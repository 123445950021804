export default {
  isMobile: (mediaQuery) => {
    return -1 !== ['xs', 'xsPlus', 'xsHd', 'sm'].indexOf(mediaQuery);
  },
  isTablet: (mediaQuery) => {
    return -1 !== ['md', 'lg'].indexOf(mediaQuery);
  },
  isDesktop: (mediaQuery) => {
    return -1 !== ['xl', 'xlHd', 'xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isXsHdMinus: (mediaQuery) => {
    return -1 !== ['xs', 'xsPlus', 'xsHd'].indexOf(mediaQuery);
  },
  isSmMinus: (mediaQuery) => {
    return -1 !== ['xs', 'xsPlus', 'xsHd', 'sm'].indexOf(mediaQuery);
  },
  isMdMinus: (mediaQuery) => {
    return (
      -1 !== ['xs', 'xsPlus', 'xsHd', 'sm', 'smHd', 'md'].indexOf(mediaQuery)
    );
  },
  isMdPlus: (mediaQuery) => {
    return -1 !== ['md', 'lg', 'xl', 'xlHd', 'xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isLgMinus: (mediaQuery) => {
    return -1 !== ['xs', 'xsHd', 'sm', 'md', 'lg'].indexOf(mediaQuery);
  },
  isLgPlus: (mediaQuery) => {
    return -1 !== ['lg', 'xl', 'xlHd', 'xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isXlPlus: (mediaQuery) => {
    return -1 !== ['xl', 'xlHd', 'xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isXlHdPlus: (mediaQuery) => {
    return -1 !== ['xlHd', 'xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isXxlPlus: (mediaQuery) => {
    return -1 !== ['xxl', 'xxxl'].indexOf(mediaQuery);
  },
  isXxxlPlus: (mediaQuery) => {
    return -1 !== ['xxxl'].indexOf(mediaQuery);
  }
};
