import { doRequest } from '@/api/utils';

export async function getFeatureFlag($axios, name) {
  const { data = null } = await doRequest(
    $axios,
    'GET',
    `/feature-flags/${name}`
  );
  return data;
}
