import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: {
    xs: 320,
    xsPlus: 375,
    xsHd: 480,
    sm: 576,
    smHd: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xlHd: 1360,
    xxl: 1600,
    xxxl: Infinity
  }
});
