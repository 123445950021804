<template>
  <div class="swibeco-banner">
    <div>{{ $t('components.banner.swibeco.text') }}</div>
    <img src="~/assets/img/partner/swibeco.svg" height="32" alt="" />
  </div>
</template>

<script>
export default {
  name: 'BannerSwibeco'
};
</script>

<style lang="scss" scoped>
.swibeco-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 6px 0 10px;
  background-color: antiquewhite;
  color: $mine-shaft;

  > div {
    padding-top: 6px;
  }
}
</style>
