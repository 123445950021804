<template>
  <div class="modal" :class="{ 'modal--height-limit': isAppleDevice }">
    <modal
      :name="modalName"
      adaptive
      focusTrap
      :width="width"
      :height="height"
      :clickToClose="clickToClose"
      @opened="$emit('opened', $event)"
      @closed="$emit('closed', $event)"
    >
      <div v-show="hasHeader" class="modal__header">
        <slot name="header" />
        <Button theme="transparent" class="modal__close" @click="hide">
          <CrossSvg class="modal__close__svg" />
        </Button>
      </div>
      <div class="modal__body" :style="bodyStyle">
        <slot />
      </div>
      <div v-show="hasFooter" class="modal__footer">
        <slot name="footer" />
      </div>
      <Loader :value="loading" />
    </modal>
  </div>
</template>

<script>
import Loader from '@/components/Loading/Loader';
import Button from '@/components/Button/Button';
import CrossSvg from '~/assets/img/cross.svg?inline';
import isNil from 'lodash.isnil';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default {
  name: 'GenericModal',
  components: { Loader, Button, CrossSvg },
  props: {
    name: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    bodyPadding: {
      type: Number,
      default: 0
    },
    width: {
      type: [Number, String],
      default: 'auto'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    clickToClose: {
      type: Boolean,
      default: true
    },
    overflowY: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    isAppleDevice() {
      return this.$device.isIos;
    },
    modalName() {
      if (isNil(this.name)) return `${getRandomInt(9999)}-modal`;
      return `${this.name}-modal`;
    },
    hasHeader() {
      this.isMounted;
      return !isNil(this.$slots.header);
    },
    hasFooter() {
      this.isMounted;
      return !isNil(this.$slots.footer);
    },
    bodyStyle() {
      const style = {};
      this.bodyPadding && (style.padding = `${this.bodyPadding}px`);
      this.overflowY && (style.overflowY = this.overflowY);
      return style;
    }
  },
  mounted() {
    if (!isNil(this.name)) {
      this.$bus.$on(`modal:${this.name}:show`, this.show);
      this.$bus.$on(`modal:${this.name}:hide`, this.hide);
    }
    this.isMounted = true;
  },
  beforeDestroy() {
    if (!isNil(this.name)) {
      this.$bus.$off(`modal:${this.name}:show`, this.show);
      this.$bus.$off(`modal:${this.name}:hide`, this.hide);
    }
  },
  methods: {
    show() {
      this.$modal.show(this.modalName);
    },
    hide() {
      this.$modal.hide(this.modalName);
    },
    onOpened() {
      if (this.isAppleDevice) {
        try {
          window.scrollTo(0, window.scrollY + 1);
        } catch {}
      }
      this.$emit('opened', $event);
    }
  }
};
</script>

<style lang="scss" scoped src="./generic-modal.scss"></style>
