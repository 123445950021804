import { useAuthStore } from '@/stores/auth';
import { useMercureStore } from '@/stores/mercure';
import isNil from 'lodash.isnil';
import { sleep } from '@/misc/utils';

export default function (ctx, inject) {
  const { $bus } = ctx;

  const authStore = useAuthStore();
  const mercureStore = useMercureStore();

  authStore.$subscribe((mutation) => {
    if (mutation.type !== 'patch object') return;
    const { user = null } = mutation.payload;
    if (null === user) return;
    !user && mercureStore.clearMercureData();
    user?.id && mercureStore.fetchMercureData(user.id);
  });

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(async () => {
      await sleep(2_000); // Wait 2s
      const { $auth } = ctx;
      $auth?.user?.id && mercureStore.fetchMercureData($auth.user.id);
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      const { $auth } = ctx;
      const { type = null, data = null } = event.data;
      if (isNil(type)) return;

      if (window.mercure?.debug) {
        console.group('[mercure] on message');
        console.info('event data', event.data);
        console.groupEnd();
      }

      // Mercure connection state
      type === 'mercure-connected' && mercureStore.setConnectedState(true);
      type === 'mercure-disconnected' && mercureStore.setConnectedState(false);

      // Default mercure message
      type === 'mercure' && $bus.$emit('mercure:message', data);

      // Message to reload mercure authorization
      type === 'reload-mercure-authorization' &&
        mercureStore.fetchMercureData($auth.user.id);
    });

    const checkMercureConnection = async () => {
      const { $auth } = ctx;
      if (!$auth || !$auth.loggedIn) return;
      // Send a message to check connection state
      navigator.serviceWorker.controller &&
        navigator.serviceWorker.controller.postMessage({
          action: 'check-connection'
        });
      // Wait the time to reiceive response
      await sleep(500);
      if (window.mercure?.debug) {
        console.group('[mercure] check connection');
        console.info('user ID', $auth.user.id);
        console.info('isConnectable', mercureStore.isConnectable);
        console.info('connected', mercureStore.connected);
        console.groupEnd();
      }
      // Eventually, reconnect
      mercureStore.isConnectable &&
        !mercureStore.connected &&
        mercureStore.fetchMercureData($auth.user.id);
    };
    setTimeout(checkMercureConnection, 2_500);
    setInterval(checkMercureConnection, 30_000);
  }

  inject('mercure', {
    subscribe({ hubUrl, authorization, topics }) {
      if (!('serviceWorker' in navigator)) return;
      navigator.serviceWorker.controller &&
        navigator.serviceWorker.controller.postMessage({
          hubUrl,
          authorization,
          topics
        });
    },
    disconnectAndClear() {
      if (!('serviceWorker' in navigator)) return;
      navigator.serviceWorker.controller &&
        navigator.serviceWorker.controller.postMessage({
          action: 'disconnect-and-clear'
        });
    }
  });
}
