import { defineStore } from 'pinia';
import isNil from 'lodash.isnil';
import {
  addBlockedUser,
  addFollowedUser,
  getBlockedUsers,
  getFollowedUser,
  removeBlockedUser,
  removeFollowedUser,
  getNotifications,
  likeItem,
  unlikeItem,
  getAllFavoriteItemsIds,
  getFavoriteBrands,
  getFavoriteColors,
  getSearches
} from '@/api/user';
import { extractUuid } from '@/misc/string';

export const useUserStore = defineStore('user', {
  state: () => ({
    followedUsers: null,
    blockedUsers: null,
    notifications: null,
    favoriteItemsIds: null,
    favoriteItemsIdsMapping: null,
    favoriteBrands: null,
    favoriteColors: null,
    searches: null
  }),
  getters: {
    followedUsersInitialized(state) {
      return state.followedUsers !== null;
    },
    isFollowedUser(state) {
      return (userId) =>
        state.followedUsers?.some((user) => user.id === userId) || false;
    },
    isBlockedUser(state) {
      return (userId) =>
        state.blockedUsers?.some((user) => user.id === userId) || false;
    },
    hasFavoriteItem(state) {
      return (itemId) => state.favoriteItemsIds?.includes(itemId) || false;
    },
    blockedUsersInitialized(state) {
      return state.blockedUsers !== null;
    },
    unreadNotifications(state) {
      if (isNil(state.notifications)) return null;
      return state.notifications.filter((n) => isNil(n.readAt));
    }
  },
  actions: {
    async fetchFollowedUser() {
      const { $auth, $api } = this.$nuxt;
      try {
        const followedUsers = await $api.doRequest(
          getFollowedUser,
          $auth.user.id
        );
        this.$patch({
          followedUsers: followedUsers['hydra:member'] || []
        });
      } catch {}
      return true;
    },
    async fetchBlockedUser() {
      const { $auth, $api } = this.$nuxt;
      try {
        const blockedUsers = await $api.doRequest(
          getBlockedUsers,
          $auth.user.id
        );
        this.$patch({
          blockedUsers: blockedUsers['hydra:member'] || []
        });
      } catch {}
      return true;
    },
    async fetchNotifications() {
      const { $auth, $api } = this.$nuxt;
      try {
        const notifications = await $api.doRequest(
          getNotifications,
          $auth.user.id
        );
        this.$patch({ notifications: notifications['hydra:member'] || [] });
      } catch {}
      return true;
    },
    async fetchFavoriteItemsIds() {
      const { $auth, $api } = this.$nuxt;
      let favoriteItemsIds = [];
      let favoriteItemsIdsMapping = {};
      try {
        favoriteItemsIds = await $api.doRequest(
          getAllFavoriteItemsIds,
          $auth.user.id
        );
        favoriteItemsIdsMapping = favoriteItemsIds.reduce((acc, data) => {
          acc[data.itemId] = data.id;
          return acc;
        }, {});
        favoriteItemsIds = favoriteItemsIds.map((data) => data.itemId);
      } catch {}
      this.$patch({ favoriteItemsIds, favoriteItemsIdsMapping });
      return true;
    },
    async fetchFavoriteBrands() {
      const { $auth, $api } = this.$nuxt;
      const favoriteBrands = [];
      let maxPage = 1;
      const perPage = 30;
      try {
        const data = await $api.doRequest(
          getFavoriteBrands,
          $auth.user.id,
          perPage,
          1
        );
        const _last = data['hydra:view']?.['hydra:last'] || null;
        if (!isNil(_last)) {
          const regex = new RegExp('page=(\\d+)');
          const m = regex.exec(_last);
          maxPage = parseInt(m?.[1] || 1);
        }
        favoriteBrands.push(...(data['hydra:member'] || []));
      } catch {}
      // Fetch other pages
      if (maxPage > 1) {
        const promises = [];
        for (let newPage = 2; newPage <= maxPage; newPage++) {
          promises.push(
            $api
              .doRequest(getFavoriteBrands, $auth.user.id, perPage, newPage)
              .then((data) => {
                favoriteBrands.push(...(data['hydra:member'] || []));
              })
          );
        }
        await Promise.all(promises);
      }
      this.$patch({ favoriteBrands });
      return true;
    },
    async fetchFavoriteColors() {
      const { $auth, $api } = this.$nuxt;
      let favoriteColors = [];
      try {
        favoriteColors = await $api.doRequest(getFavoriteColors, $auth.user.id);
      } catch {}
      this.$patch({ favoriteColors });
      return true;
    },
    async fetchSearches() {
      const { $auth, $api } = this.$nuxt;
      try {
        const data = await $api.doRequest(getSearches, $auth.user.id);
        this.$patch({ searches: data['hydra:member'] || [] });
      } catch {}
      return true;
    },
    async followUser(userId) {
      const { $auth, $api } = this.$nuxt;
      try {
        await $api.doRequest(addFollowedUser, $auth.user.id, {
          user: `/users/${userId}`
        });
        this.fetchFollowedUser();
      } catch {}
      return true;
    },
    async unfollowUser(userId) {
      const { $auth, $api } = this.$nuxt;
      try {
        await $api.doRequest(removeFollowedUser, $auth.user.id, {
          user: `/users/${userId}`
        });
        this.fetchFollowedUser();
      } catch {}
      return true;
    },
    async blockUser(userId) {
      const { $auth, $api, $toast } = this.$nuxt;
      try {
        await $api.doRequest(addBlockedUser, $auth.user.id, {
          user: `/users/${userId}`
        });
        this.fetchBlockedUser();
      } catch (err) {
        const { response } = err;
        if (
          !isNil(response) &&
          response.status >= 400 &&
          response.status < 500
        ) {
          const errorMessage = response.data?.['hydra:description'] || null;
          !isNil(errorMessage) && $toast?.warning(errorMessage);
        }
      }
      return true;
    },
    async unblockUser(userId) {
      const { $auth, $api } = this.$nuxt;
      try {
        await $api.doRequest(removeBlockedUser, $auth.user.id, {
          user: `/users/${userId}`
        });
        this.fetchBlockedUser();
      } catch {}
      return true;
    },
    async likeItem(itemIri) {
      const { $api } = this.$nuxt;
      try {
        await $api.doRequest(likeItem, itemIri);
        this.fetchFavoriteItemsIds();
      } catch {
        return false;
      }
      return true;
    },
    async unlikeItem(itemIri) {
      const { $api } = this.$nuxt;
      try {
        const itemId = extractUuid(itemIri);
        const userFavoriteItemId = this.favoriteItemsIdsMapping[itemId];
        if (userFavoriteItemId) {
          await $api.doRequest(unlikeItem, userFavoriteItemId);
          this.fetchFavoriteItemsIds();
        }
      } catch {
        return false;
      }
      return true;
    }
  }
});
