import debounce from 'lodash.debounce';

export default function (ctx, inject) {
  const {
    app: { router }
  } = ctx;
  const savedPositions = {};
  let historyLength = [0, 0];
  let currentFullPath = null;

  const storePosition = (fullPath, position) => {
    const data = savedPositions[fullPath] || {};
    data.position = position;
    savedPositions[fullPath] = data;
  };

  const storePositionState = (fullPath, state) => {
    const data = savedPositions[fullPath] || {};
    data.state = state;
    savedPositions[fullPath] = data;
  };

  const fetchPosition = (fullPath) => {
    return savedPositions[fullPath] || null;
  };

  // Debounced function that staore scroll
  const _scrollStore = debounce(() => {
    if (!window.scrollX && !window.scrollY) return;
    storePosition(currentFullPath, { x: window.scrollX, y: window.scrollY });
  }, 60);

  // Register scroll listener
  window.addEventListener('scroll', _scrollStore);

  // Set current path full path
  router.afterEach((to, from) => {
    currentFullPath = to.fullPath;
    const [_old, _new] = historyLength;
    historyLength = [_new, window.history.length];
  });

  inject('scrollBehavior', {
    storePositionState(fullPath, state) {
      storePositionState(fullPath, state);
    },
    getPosition(fullPath) {
      return fetchPosition(fullPath);
    },
    hasPopState() {
      const [_old, _new] = historyLength;
      return _old === _new;
    }
  });
}
