import markerSDK from '@marker.io/browser';
import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import throttle from 'lodash.throttle';

export default async function (ctx, inject) {
  const projectId = process.env.NUXT_ENV_MARKER_IO_PROJECT_ID;
  if (isEmpty(projectId)) return;

  let widget = null;

  const resizeHandler = () => {
    if (isNil(widget)) return;
    const isVisible = widget.isVisible();
    if (window.innerWidth <= 768 && isVisible) {
      widget.hide();
    } else if (window.innerWidth > 768 && !isVisible) {
      widget.show();
    }
  };

  const _throttledResizeHandler = throttle(resizeHandler, 150);
  window.addEventListener('resize', _throttledResizeHandler);

  setTimeout(async () => {
    widget = await markerSDK.loadWidget({
      project: projectId,
      keyboardShortcuts: false
    });

    widget.on('show', resizeHandler);

    const { $auth } = ctx || {};
    if ($auth?.loggedIn) {
      // Identify reporter
      widget.setReporter({
        email: $auth.user.email,
        fullName: `${$auth.user.firstName} ${$auth.user.lastName}`
      });
    }

    resizeHandler();
  }, 500);

  inject('markerIo', {
    displayForm() {
      if (isNil(widget)) return;
      widget.capture('fullscreen');
    },
    showWidget() {
      if (isNil(widget)) return;
      widget.show();
    },
    hideWidget() {
      if (isNil(widget)) return;
      widget.hide();
    }
  });
}
