import { doRequest } from '@/api/utils';
import isNil from 'lodash.isnil';

export async function createBundle($axios, items) {
  const { data } = await doRequest($axios, 'POST', '/bundles', { items });
  return data;
}

export async function patchBundle($axios, bundleId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/bundles/${bundleId}`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function addItemToBundle($axios, bundleId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/bundles/${bundleId}/add-item`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function removeItemFromBundle($axios, bundleId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/bundles/${bundleId}/remove-item`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function createOffer($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/offers', postData);
  return data;
}

export async function acceptOffer($axios, offerId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/offers/${offerId}/accept`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function rejectOffer($axios, offerId, silent = false) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/offers/${offerId}/reject`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' },
      params: { silent }
    }
  );
  return data;
}

export async function getConversations($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/conversations`
  );
  let lastPage = data?.['hydra:view']?.['hydra:last'];
  if (isNil(lastPage)) {
    return data['hydra:member'] || null;
  }
  const matches = lastPage.match(/page=(\d+)/);
  lastPage = parseInt(matches[1] || 1);
  if (lastPage <= 1) {
    return data['hydra:member'] || null;
  }
  const all = data['hydra:member'] || [];
  const promises = [];
  for (let page = 2; page <= lastPage; page++) {
    promises.push(
      doRequest(
        $axios,
        'GET',
        `/users/${userId}/conversations`,
        {},
        { params: { page } }
      )
        .then((resp) => resp.data?.['hydra:member'] || [])
        .catch((_) => null)
    );
  }
  const restOfConversations = await Promise.all(promises);
  for (const conversations of restOfConversations) {
    !isNil(conversations) && all.push(...conversations);
  }
  return all;
}

export async function getConversationPage($axios, userId, page = 1) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/conversations`,
    {},
    { params: { page } }
  );
  return data['hydra:member'] || null;
}

export async function deleteConversation($axios, conversationId) {
  const { data } = await doRequest(
    $axios,
    'DELETE',
    `/conversations/${conversationId}`
  );
  return data;
}

export async function postConversation($axios, postData) {
  const { data } = await doRequest($axios, 'POST', `/conversations`, postData);
  return data;
}

export async function getConversation($axios, conversationId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/conversations/${conversationId}`
  );
  return data;
}

export async function postConversationMessage($axios, postData) {
  const { data } = await doRequest(
    $axios,
    'POST',
    `/conversation-messages`,
    postData
  );
  return data;
}

export async function readConversationMessage($axios, conversationMessageId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/conversation-messages/${conversationMessageId}/read`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function readConversationMessageAsAuthor(
  $axios,
  conversationMessageId
) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/conversation-messages/${conversationMessageId}/author-read`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function translateConversationMessage(
  $axios,
  conversationMessageId
) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/conversation-messages/${conversationMessageId}/translate`
  );
  return data;
}

export async function getDisputeReasons($axios, seller = false) {
  const { data } = await doRequest(
    $axios,
    'GET',
    '/dispute-reasons',
    {},
    { params: { seller: seller ? 1 : 0 } }
  );
  return data['hydra:member'] || null;
}

export async function postDispute($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/disputes', postData);
  return data;
}

export async function proposeRefundOffer($axios, orderId, amount) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/dispute-refund-offer-proposal`,
    { amount },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function proposeReturn($axios, orderId, postData) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/return-proposal`,
    postData,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function generateLabel($axios, orderId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/generate-label.jsonld`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function validateOrder($axios, orderId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/validate`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function validateReturn($axios, orderId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/validate-return`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function cancelOrder($axios, orderId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/cancel`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function askForSupportIntervention($axios, orderId) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/orders/${orderId}/support-intervention`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}
