export const flipObject = (object) => {
  return Object.entries(object).reduce((ret, entry) => {
    const [key, value] = entry;
    ret[value] = key;
    return ret;
  }, {});
};

export const convertArrayToObject = (arrayToConvert, keyFunction) => {
  let newObject = {};
  for (let item of arrayToConvert) {
    newObject[keyFunction(item)] = item;
  }
  return newObject;
};

// We transform object to array because if object keys are integer the sort will not work
export const sortObjectToArrayFromArray = (objectToSort, arrayReference) => {
  let newArray = [];
  for (let reference of arrayReference) {
    if (typeof objectToSort[reference] !== 'undefined') {
      newArray.push(objectToSort[reference]);
    }
  }
  return newArray;
};

export const getUrlFromPath = (path) => {
  return new URL(path, 'https://fake.url');
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const roundNumberToTwoDecimal = (number) => {
  if (typeof number !== 'number') {
    return number;
  }

  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const getBiggestDivisible = (number, total) => {
  let max = Math.min(number, total);
  for (let j = number; j <= total; j++) {
    if (j % number === 0 && j > max) {
      max = j;
    }
  }
  return max;
};
