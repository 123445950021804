import * as Sentry from '@sentry/browser';
import isNil from 'lodash.isnil';

const DEFAULT_OPTIONS = { showToast: false, sentryCapture: true };

export const captureErrorMixin = {
  methods: {
    async try(callback, catchCallback, finallyCallback, options = {}) {
      options = { ...DEFAULT_OPTIONS, ...options };
      try {
        return (
          (!isNil(callback) &&
            typeof callback === 'function' &&
            (await callback.apply(this))) ||
          undefined
        );
      } catch (error) {
        const { response } = error;
        if (
          !isNil(response) &&
          response.status >= 400 &&
          response.status < 500
        ) {
          const errorMessage = response.data?.['hydra:description'] || null;
          if (!isNil(errorMessage)) {
            options.showToast && this.$toast?.warning(errorMessage);
            !isNil(catchCallback) && (await catchCallback.apply(this, [error]));
            return;
          }
        }
        process.env.NODE_ENV === 'development' && console.error(error);
        options.sentryCapture && Sentry.captureException(error);
        options.showToast &&
          this.$toast?.error('An error occured, please try again later!');
        !isNil(catchCallback) && (await catchCallback.apply(this, [error]));
      } finally {
        !isNil(finallyCallback) && (await finallyCallback.apply(this));
      }
    }
  }
};
