import { useAuthStore } from '@/stores/auth';
import isNil from 'lodash.isnil';

export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (isNil(to)) return;
    if (isNil(to.name)) return;
    if (to.name.match(/Account-/) || to.name.match(/Checkout-/)) {
      const authStore = useAuthStore();
      authStore.$patch({ redirectedFrom: to?.fullPath });
    }
  });
};
