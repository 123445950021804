import { defineStore } from 'pinia';
import { register, impersonationForce } from '@/api/auth';
import isNil from 'lodash.isnil';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    busy: false,
    user: null,
    loggedIn: false,
    strategy: undefined,
    redirect: undefined,
    redirectedFrom: null,
    sessId: null
  }),
  getters: {},
  actions: {
    setSessId(sessId) {
      this.$patch({ sessId });
    },
    async register(username, email, password, locale, partner, newsletter) {
      const { $axios } = this.$nuxt;
      if (!$axios) return console.error('$axios not found');
      await register(
        $axios,
        email,
        username,
        password,
        locale,
        partner,
        newsletter
      );
      return true;
    },
    async impersonate(email) {
      if (window.localStorage) {
        window.localStorage.setItem('_switch_user', email);
        window.location.reload();
      }
    },
    async exitImpersonation() {
      if (window.localStorage) {
        window.localStorage.removeItem('_switch_user');
        window.location.reload();
      }
    },
    async impersonateForce(email) {
      const { $auth, $api } = this.$nuxt;
      const { user } = $auth;
      if (isNil(user)) return;
      try {
        const data = await $api.doRequest(impersonationForce, email, user.id);
        if (data.token && data.refresh_token) {
          await $auth.setUserToken(data.token, data.refresh_token);
          // window.location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  persist: {
    enabled: false,
    strategies: [
      {
        key: '__auth',
        storage: typeof localStorage !== 'undefined' ? localStorage : undefined
      }
    ]
  }
});
