import { doRequest } from '@/api/utils';

export async function getCart($axios) {
  const { data } = await doRequest($axios, 'GET', '/cart');
  return data;
}

export async function cartAdd($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/cart/add', postData);
  return data;
}

export async function cartClear($axios) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    '/cart/clear',
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function patchCart($axios, postData) {
  const { data } = await doRequest($axios, 'PATCH', '/cart', postData, {
    headers: { 'Content-Type': 'application/merge-patch+json' }
  });
  return data;
}

export async function initiatePayment($axios, cardId) {
  const { data } = await doRequest($axios, 'POST', '/cart/payment', {
    cardId
  });
  return data;
}

export async function getTransaction($axios, transactionId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/transactions/${transactionId}`
  );
  return data;
}

export async function getConversationFromTransaction($axios, transactionId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/transactions/${transactionId}/conversation`
  );
  return data;
}
