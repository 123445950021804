<template>
  <GenericModal
    ref="modal"
    width="420"
    :body-padding="10"
    @closed="readNotifications"
  >
    <template #header>
      {{ $t('components.notifications-modal.heading') }}
    </template>
    <div class="notifications-modal__content">
      <NotificationDropdown
        :notifications="notifications"
        @notification:redirect="hide"
      />
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/Modal/GenericModal';
import NotificationDropdown from '@/components/Header/components/NotificationDropdown/NotificationDropdown';
import { useUserStore } from '@/stores/user';
import { readNotification } from '@/api/user';
import { mapState } from 'pinia';
import { captureErrorMixin } from '@/mixins/captureError';
import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';

export default {
  name: 'NotificationsModal',
  components: { GenericModal, NotificationDropdown },
  mixins: [captureErrorMixin],
  computed: {
    ...mapState(useUserStore, ['notifications', 'unreadNotifications']),
    unreadNotificationCount() {
      if (isNil(this.unreadNotifications)) return 0;
      return this.unreadNotifications.length;
    }
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    async readNotifications() {
      if (isEmpty(this.unreadNotifications)) return;
      await Promise.all(
        this.unreadNotifications.map((n) =>
          this.try(
            async () => await this.$api.doRequest(readNotification, n.id)
          )
        )
      );
      this.$emit('notifications:reload');
    }
  }
};
</script>

<style lang="scss" src="./notifications-modal.scss" scoped></style>
