import isNil from 'lodash.isnil';
import qs from 'qs';

export const formatException = (e, method, url) => {
  let error = new Error(
    'Unable to perform request ' +
      method +
      ' "' +
      url +
      '" due to error "' +
      e.message +
      '"'
  );
  error.response = e.response;
  error.original = e;
  error.stack = error?.stack?.split('\n').join('\n') + '\n' + e.stack;

  return error;
};

export const handleResponseError = (error) => {
  const response = error.response;
  if (!isNil(response) && response.status === 400) {
    return { success: false, data: response.data };
  }
  throw error;
};

export const doRequest = async (axios, method, url, data, config) => {
  const debug = typeof window !== 'undefined' ? window.axiosDebug : false;
  let stack = '';
  if (process.server) {
    stack = new Error().stack;
  }
  config = {
    ...config,
    paramsSerializer: (params) => qs.stringify(params)
  };
  try {
    const tStart = Date.now();
    const response = await axios.request({
      method,
      url,
      data,
      ...config
    });
    const tEnd = Date.now();
    if (debug) {
      const queryParams = config.params && qs.stringify(config.params);
      const fullUrl = url + (queryParams ? `?${queryParams}` : '');
      console.info(`[${method}] ${fullUrl} takes ${tEnd - tStart}ms.`);
    }
    return response;
  } catch (e) {
    if (isNil(axios) || axios.isCancel(e)) {
      throw e;
    }
    if (process.server) {
      e.stack = e.stack + '\n' + stack;
    }
    throw formatException(e, method, url);
  }
};

export const fetchIri = async (axios, method, iri, requestData, config) => {
  const { data } = await doRequest(axios, method, iri, requestData, config);
  return data;
};
