import Vue from 'vue';
import { useAuthStore } from '@/stores/auth';
import { ref, set } from '@nuxtjs/composition-api';

class NuxtStorePlugin {
  constructor(ctx) {
    this._namespace = 'auth'; // equals with auth.vuex.namespace in nuxt.config
    this._watcherVM = new Vue();
    this._ctx = ctx;
  }
  // Fake vuex state contain module 'auth'
  get state() {
    const this$1 = this;
    return {
      // 2. implement full state getter
      get [this$1._namespace]() {
        var _a, _b;
        // TODO: return undefined
        return (_b =
          (_a = this$1.authStore) === null || _a === void 0
            ? void 0
            : _a.$state) !== null && _b !== void 0
          ? _b
          : {};
      }
    };
  }
  // Fake vuex registerModule
  registerModule(name, module, _options) {
    this._namespace = name;
    // 1. Create Auth Store
    const initState = module.state();
    this._ctx.$pinia.use((params) => {
      const { store } = params;
      if (store.$id === 'auth') {
        Object.keys(initState).forEach((prop) => {
          if (!(prop in store.$state)) {
            const refValue = ref(initState[prop]);
            set(store.$state, prop, refValue);
            set(store, prop, refValue);
          }
        });
      }
    });
    this.authStore = useAuthStore(this._ctx.$pinia);
    this.authStore.$state = initState;
  }
  // Fake vuex commit
  commit(mutationName, payload) {
    if (mutationName === `${this._namespace}/SET` && this.authStore) {
      // 3. implement state property setter
      this.authStore.$patch({
        [payload.key]: payload.value
      });
    }
  }
  // Fake vuex watch
  watch(getter, cb, options) {
    const this$1 = this;
    this._watcherVM.$watch(
      // 4. PINIA getters direct access as state properties
      () => getter(this$1.state, this$1.state),
      cb,
      options
    );
  }
}
// NUXT PLUGIN for inject $store
const piniaAuthPlugin = (context) => {
  // @ts-ignore
  context.store = new NuxtStorePlugin(context);
};

export default piniaAuthPlugin;
