<template>
  <div
    class="button"
    :class="{
      'button--outline': outline,
      'button--disabled': disabled,
      'button--width-auto': widthAuto,
      [`button--size-${size}`]: size,
      [`button--theme-${theme}`]: theme
    }"
    @click="handleClick"
  >
    <template v-if="to">
      <nuxt-link v-if="isInternal" :to="to">
        <slot />
      </nuxt-link>
      <a v-else :href="to" :target="target" rel="noopener noreferrer">
        <slot />
      </a>
    </template>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import isNil from 'lodash.isnil';

const THEMES = [
  'orange',
  'orange-swap',
  'green',
  'red',
  'white',
  'black',
  'transparent'
];
const SIZES = ['small', 'normal', 'large'];

export default {
  name: 'Button',
  props: {
    theme: {
      type: String,
      default: 'orange',
      validator(val) {
        return THEMES.includes(val);
      }
    },
    outline: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'normal',
      validator(val) {
        return SIZES.includes(val);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: '_self'
    },
    preventDoubleClick: {
      type: Boolean,
      default: true
    },
    doubleClickThreshold: {
      type: Number,
      default: 500
    },
    widthAuto: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ clickTimeoutHandler: null }),
  computed: {
    isInternal() {
      if (!this.to) return false;
      return !this.to.match(/^https?:\/\//)?.length;
    }
  },
  methods: {
    handleClick(event) {
      if (this.preventDoubleClick) {
        if (!isNil(this.clickTimeoutHandler)) return;
        this.clickTimeoutHandler = setTimeout(
          this.clearClickTimeout,
          this.doubleClickThreshold
        );
      }
      this.$emit('click', event);
    },
    clearClickTimeout() {
      this.clickTimeoutHandler = null;
    }
  }
};
</script>

<style lang="scss" scoped src="./button.scss"></style>
