import Vue from 'vue';
import ZoomOnHover from 'vue-zoom-on-hover';

require('@/components/global/withRoot');

Vue.use(ZoomOnHover);

if (process.client) {
  const lineClamp = require('vue-line-clamp');
  Vue.use(lineClamp, {
    // plugin options
  });
}
