import { useAuthStore } from '@/stores/auth';
import { useMenuStore } from '@/stores/menu';

export default (ctx) => {
  const authStore = useAuthStore();
  const menuStore = useMenuStore();
  authStore.$subscribe((mutation) => {
    if (mutation.type !== 'patch object') return;
    const { user = null } = mutation.payload;
    if (null === user) return;
    const { preferences: { locale = null } = {} } = user;
    const { i18n } = ctx;
    if (locale !== null && locale !== i18n.locale) {
      i18n.setLocale(locale);
      menuStore.nuxtServerInit();
    }
  });
};
