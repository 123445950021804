import { getMediaContentUrl } from '@/misc/media-object';

export default {
  methods: {
    mediaSrc(media, format) {
      return getMediaContentUrl(
        media,
        format,
        this.$mediaObject.isVariantsEnabled()
      );
    },
    mediaSrcset(media, format) {
      const defaultSrc = this.mediaSrc(media, format);
      const twoTimesSrc = getMediaContentUrl(
        media,
        `${format}@2x`,
        this.$mediaObject.isVariantsEnabled()
      );
      return `${defaultSrc}, ${twoTimesSrc} 2x`;
    }
  }
};
