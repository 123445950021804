export default async function (ctx, inject) {
  const {
    $feature,
    app: { router }
  } = ctx;

  let variantsEnabled = await $feature.isActive('media-variants', true);
  inject('mediaObject', {
    isVariantsEnabled() {
      return variantsEnabled;
    }
  });

  router.beforeEach(async (to, from, next) => {
    variantsEnabled = await $feature.isActive('media-variants', true);
    next();
  });
}
