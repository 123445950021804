import { useMenuStore } from '@/stores/menu';
import { useCheckoutStore } from '@/stores/checkout';

export default async function ({ $pinia }) {
  // if (process.server) {
  const menuStore = useMenuStore($pinia);
  await menuStore.nuxtServerInit();

  // const checkoutStore = useCheckoutStore($pinia);
  // await checkoutStore.nuxtServerInit();
  // }
}
