import { defineStore } from 'pinia';
import { deleteConversation, getConversations } from '@/api/order';
import isNil from 'lodash.isnil';

export const useInboxStore = defineStore('inbox', {
  state: () => ({
    conversations: null,
    listScrollTop: 0
  }),
  getters: {
    initialized(state) {
      return !isNil(state.conversations);
    },
    orderedConversations(state) {
      if (isNil(state.conversations)) return null;
      const filteredConversations = [...state.conversations].filter(
        (c) => !c.hidden
      );
      filteredConversations.sort((b, a) => {
        const aDate = a.lastMessage?.messageAt || a.createdAt;
        const bDate = b.lastMessage?.messageAt || b.createdAt;
        if (!bDate) return 1;
        else if (!aDate) return -1;
        return aDate.localeCompare(bDate);
      });
      return filteredConversations;
    },
    unreadConversations(state) {
      if (isNil(state.conversations)) return null;
      // Filter hidden conversation (only auto-rejected offers)
      const filteredConversations = state.conversations.filter(
        (c) => !c.hidden
      );
      return (userId) =>
        filteredConversations.reduce((acc, conversation) => {
          const sellerHasUnreadMessages =
            conversation.seller?.id === userId &&
            conversation.sellerUnreadMessages;
          const buyerHasUnreadMessages =
            conversation.buyer?.id === userId &&
            conversation.buyerUnreadMessages;
          (sellerHasUnreadMessages || buyerHasUnreadMessages) &&
            acc.push(conversation);
          return acc;
        }, []);
    }
  },
  actions: {
    async loadConversations(userId, options) {
      if (options?.onlyIfNull && !isNil(this.conversations)) return;
      const { $api } = this.$nuxt;
      try {
        const entries = await $api.doRequest(getConversations, userId);
        this.$patch({ conversations: entries });
      } catch (err) {
        throw err;
      }
    },
    async deleteConversation(conversationId) {
      const { $api } = this.$nuxt;
      try {
        await $api.doRequest(deleteConversation, conversationId);
        this.$patch({
          conversations: this.conversations.filter(
            (conversation) => conversation.id !== conversationId
          )
        });
      } catch (err) {
        throw err;
      }
    },
    getConversationWithSeller(sellerId) {
      if (isNil(this.conversations)) return null;
      return this.conversations.find(
        (conversation) =>
          isNil(conversation.item) && conversation.seller.id === sellerId
      );
    },
    getItemConversation(itemIri) {
      if (isNil(this.conversations)) return null;
      return this.conversations.find(
        (conversation) => conversation.item?.['@id'] === itemIri
      );
    },
    getSellerConversation(sellerId) {
      if (isNil(this.conversations)) return null;
      return this.conversations.find(
        (conversation) =>
          conversation.seller?.id === sellerId && isNil(conversation.item)
      );
    },
    setListScrollTop(scrollTop) {
      this.$patch({ listScrollTop: scrollTop });
    }
  }
});
