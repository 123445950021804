<template>
  <transition name="fade">
    <div v-if="value" class="loader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    value: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped src="./loader.scss"></style>
