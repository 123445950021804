<template>
  <!--Binding css variables to use as height/width of :before -> the slider -->
  <div
    :class="{ 'toggle-slider': true, 'toggle-slider--disabled': disabled }"
    :style="getStyleObject"
  >
    <label class="switch">
      <input :id="identifier" v-model="isActive" type="checkbox" />
      <span class="track">
        <span class="handle"></span>
      </span>
    </label>
  </div>
</template>

<script>
const PROP_KEYS = {
  DIAMETER: 'diameter',
  COLOR: 'color',
  BORDER_RADIOUS: 'borderRadius',
  BORDER_WIDTH: 'borderWidth',
  WIDTH: 'width',
  HEIGHT: 'height',
  ACTIVE_COLOR: 'activeColor'
};
export default {
  name: 'toggleButton',
  props: {
    id: { type: String },
    value: { type: Boolean, default: false },
    options: { type: Object },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      handle: {
        diameter: 18, // optional
        distance: 28, // optional
        color: '#fff', // optional
        borderRadius: '50%' // optional
      },
      track: {
        color: '#ccc', // optional
        width: 50, // optional
        height: 24, // optional
        activeColor: '#f4a650', // optional
        borderWidth: 0, // optional
        borderRadius: '34px' // optional
      }
    };
  },
  computed: {
    identifier() {
      return this.id || Math.random(9999);
    },
    getHandleDistance: function () {
      let handleDistance = 0;
      if (this.options && this.options.handle && this.options.track) {
        handleDistance =
          this.options.track.width - this.options.handle.diameter;
      } else {
        handleDistance =
          this.track.width -
          this.handle.diameter -
          this.getHandleInitialDistance;
      }
      return handleDistance;
    },
    getHandleInitialDistance: function () {
      let handleDistance = (this.track.height - this.handle.diameter) / 2;
      return handleDistance;
    },
    getStyleObject: function () {
      let styleObj = {
        '--handle-diameter': this.handle.diameter + 'px',
        '--handle-color': this.handle.color,
        '--handle-border-radius': this.handle.borderRadius,
        '--handle-initial-distance': this.getHandleInitialDistance + 'px',
        '--handle-distance': this.getHandleDistance + 'px',
        '--track-color': this.track.color,
        '--track-width': this.track.width + 'px',
        '--track-height': this.track.height + 'px',
        '--track-active-color': this.track.activeColor,
        '--track-border-width': this.track.borderWidth + 'px',
        '--track-border-radius': this.track.borderRadius
      };
      return styleObj;
    },
    isActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  created() {
    this.setConfigData();
  },
  methods: {
    setConfigData() {
      if (!this.options) {
        return;
      }
      if (this.options.handle) {
        [PROP_KEYS.COLOR, PROP_KEYS.DIAMETER, PROP_KEYS.BORDER_RADIOUS].forEach(
          (element) => {
            this.setBindedProp('handle', element);
          }
        );
      }
      if (this.options.track) {
        // TODO - track border width, track border radius - 24.12.18
        [
          PROP_KEYS.COLOR,
          PROP_KEYS.WIDTH,
          PROP_KEYS.HEIGHT,
          PROP_KEYS.ACTIVE_COLOR,
          PROP_KEYS.BORDER_WIDTH,
          PROP_KEYS.BORDER_RADIOUS
        ].forEach((element) => {
          this.setBindedProp('track', element);
        });
      }
    },
    setBindedProp(key, propToBind) {
      if (this.option[key][propToBind]) {
        this[key][propToBind] = this.option[key][propToBind];
      }
    }
  }
};
</script>

<style scoped lang="scss" src="./toggle.scss"></style>
