import Vue from 'vue';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options = {
  // You can set your default options here
};

Vue.use(Toast, options);

export default function (_, inject) {
  inject('toast', Vue.$toast);
}
