<template>
  <ValidationProvider
    tag="div"
    class="input-row"
    :rules="rules"
    v-slot="{ errors }"
  >
    <div
      class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center"
    >
      <label class="input-row__label" v-if="label" :for="id">{{ label }}</label>
      <div class="input-row__input-wrapper">
        <slot>
          <FormInput
            ref="formInput"
            class="input-row__input"
            :type="type"
            :step="step"
            :id="id"
            :value="value"
            :has-error="errors.length > 0"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            v-on="inputListeners"
          />
        </slot>
        <slot name="postInput" />
      </div>
    </div>
    <transition name="slide-vertical">
      <div v-if="errors.length" class="input-row__error">
        {{ errors[0] }}
      </div>
    </transition>
    <div v-if="hint" class="input-row__hint">{{ hint }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import FormInput from '../Input/FormInput';

export default {
  components: { ValidationProvider, FormInput },
  props: {
    type: { type: String, default: 'text' },
    label: { type: String, default: null },
    value: { type: String },
    step: { type: Number, default: 0.01 },
    placeholder: { type: String, default: '' },
    rules: { type: Object | String, default: '' },
    autocomplete: { type: String, default: null },
    hint: { type: String, default: null }
  },
  computed: {
    id() {
      return 'input-' + Math.floor(Math.random() * 9999);
    },
    inputListeners() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: (event) => vm.$emit('input', event),
        keydown: (event) => vm.$emit('keydown', event),
        keyup: (event) => vm.$emit('keyup', event),
        focus: (event) => vm.$emit('focus', event),
        blur: (event) => vm.$emit('blur', event)
      });
    }
  },
  methods: {
    focus() {
      this.$refs.formInput?.focus();
    }
  }
};
</script>

<style lang="scss" scoped src="./input-row.scss"></style>
