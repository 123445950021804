<template>
  <Default :show-reinsurance="false" />
</template>

<script>
import Default from './default';

export default {
  name: 'DefaultNoReinsuranceLayout',
  components: {
    Default
  }
};
</script>
