import { defineStore } from 'pinia';
import {
  getCart,
  cartAdd,
  cartClear,
  patchCart,
  initiatePayment
} from '@/api/checkout';

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    cart: null
  }),
  getters: {},
  actions: {
    async nuxtServerInit() {
      try {
        await this.reloadCart();
      } catch {}
    },
    async reloadCart() {
      const { $api } = this.$nuxt;
      this.$patch({ cart: null });
      const data = await $api.doRequest(getCart);
      this.$patch({ cart: data });
      return data;
    },
    async clearCart(reloadCart = true) {
      const { $api } = this.$nuxt;
      await $api.doRequest(cartClear);
      reloadCart && (await this.reloadCart());
    },
    async addItemToCart(item, reloadCart = true) {
      const { $api } = this.$nuxt;
      const data = await $api.doRequest(cartAdd, {
        item: item['@id'] || `/items/${item.id}`
      });
      reloadCart && (await this.reloadCart());
      return data;
    },
    async addBundleToCart(bundle, reloadCart = true) {
      const { $api } = this.$nuxt;
      const data = await $api.doRequest(cartAdd, {
        bundle: bundle['@id']
      });
      reloadCart && (await this.reloadCart());
      return data;
    },
    async addOfferToCart(offer, reloadCart = true) {
      const { $api } = this.$nuxt;
      const data = await $api.doRequest(cartAdd, {
        offer: `/offers/${offer.id}`
      });
      reloadCart && (await this.reloadCart());
      return data;
    },
    async setCartPostalAddress(postalAddress, reloadCart = true) {
      const { $api } = this.$nuxt;
      const data = await $api.doRequest(patchCart, {
        postalAddress: postalAddress['@id']
      });
      reloadCart && (await this.reloadCart());
      return data;
    },
    async initiatePayment(cardId) {
      const { $api } = this.$nuxt;
      const data = await $api.doRequest(initiatePayment, cardId);
      return data;
    }
  }
});
