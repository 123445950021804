import isEmpty from 'lodash.isempty';

export const FORMATS = {
  item: {
    productList: 'product-list',
    productList2x: 'product-list@2x',
    smallCarousel: 'small-carousel',
    smallCarousel2x: 'small-carousel@2x',
    carousel: 'carousel',
    carousel2x: 'carousel@2x',
    notification: 'notification',
    notification2x: 'notification@2x',
    orderDetail: 'order-detail',
    orderDetail2x: 'order-detail@2x'
  },
  user: {
    header: 'header',
    header2x: 'header@2x',
    productCard: 'product-card',
    productCard2x: 'product-card@2x',
    member: 'member',
    member2x: 'member@2x',
    orderDetail: 'order-detail',
    orderDetail2x: 'order-detail@2x'
  }
};

export const getMediaContentUrl = (
  mediaObject,
  format = null,
  showVariant = false
) => {
  const { contentUrl, variants = {} } = mediaObject;
  if (!showVariant) return contentUrl;
  if (isEmpty(variants)) return contentUrl;
  return variants[format] || contentUrl;
};
