import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e35258e2 = () => interopDefault(import('../pages/Welcome/index.vue' /* webpackChunkName: "pages/Welcome/index" */))
const _338d5f86 = () => interopDefault(import('../pages/Catalog/index.vue' /* webpackChunkName: "pages/Catalog/index" */))
const _fc73efa0 = () => interopDefault(import('../pages/HowItWorks/index.vue' /* webpackChunkName: "pages/HowItWorks/index" */))
const _762ff01e = () => interopDefault(import('../pages/TermsAndConditions/index.vue' /* webpackChunkName: "pages/TermsAndConditions/index" */))
const _3c00581c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c14b5438 = () => interopDefault(import('../pages/Impressum/index.vue' /* webpackChunkName: "pages/Impressum/index" */))
const _cc24ebca = () => interopDefault(import('../pages/Auth/Logout.vue' /* webpackChunkName: "pages/Auth/Logout" */))
const _2b43bb87 = () => interopDefault(import('../pages/PrivacyPolicy/index.vue' /* webpackChunkName: "pages/PrivacyPolicy/index" */))
const _55186e74 = () => interopDefault(import('../pages/ListPage/Search.vue' /* webpackChunkName: "pages/ListPage/Search" */))
const _2a2a23f7 = () => interopDefault(import('../pages/Account/SearchHistory/index.vue' /* webpackChunkName: "pages/Account/SearchHistory/index" */))
const _4a07679e = () => interopDefault(import('../pages/Account/Inbox/index.vue' /* webpackChunkName: "pages/Account/Inbox/index" */))
const _c7504ace = () => interopDefault(import('../pages/Account/UserSettings/index.vue' /* webpackChunkName: "pages/Account/UserSettings/index" */))
const _e3d5de68 = () => interopDefault(import('../pages/Auth/PasswordResetRequest.vue' /* webpackChunkName: "pages/Auth/PasswordResetRequest" */))
const _4e050453 = () => interopDefault(import('../pages/Auth/SignIn.vue' /* webpackChunkName: "pages/Auth/SignIn" */))
const _629b5ac9 = () => interopDefault(import('../pages/Auth/SignUp.vue' /* webpackChunkName: "pages/Auth/SignUp" */))
const _b8bccb0c = () => interopDefault(import('../pages/Auth/SocialRedirect.vue' /* webpackChunkName: "pages/Auth/SocialRedirect" */))
const _2a1b9754 = () => interopDefault(import('../pages/Checkout/Cart/index.vue' /* webpackChunkName: "pages/Checkout/Cart/index" */))
const _1c8f922b = () => interopDefault(import('../pages/Checkout/Cart/old.vue' /* webpackChunkName: "pages/Checkout/Cart/old" */))
const _3bec31d8 = () => interopDefault(import('../pages/ListPage/RecoPopularForYou.vue' /* webpackChunkName: "pages/ListPage/RecoPopularForYou" */))
const _14b690b8 = () => interopDefault(import('../pages/ListPage/RecentlyViewedItems.vue' /* webpackChunkName: "pages/ListPage/RecentlyViewedItems" */))
const _2099d18b = () => interopDefault(import('../pages/ListPage/LatestPublished.vue' /* webpackChunkName: "pages/ListPage/LatestPublished" */))
const _4afecfdc = () => interopDefault(import('../pages/ListPage/RecoNewsFeed.vue' /* webpackChunkName: "pages/ListPage/RecoNewsFeed" */))
const _3fe93892 = () => interopDefault(import('../pages/ListPage/Highlighted.vue' /* webpackChunkName: "pages/ListPage/Highlighted" */))
const _1140daa0 = () => interopDefault(import('../pages/ListPage/UserFavorites.vue' /* webpackChunkName: "pages/ListPage/UserFavorites" */))
const _7e6f01a1 = () => interopDefault(import('../pages/ListPage/RecoForYou.vue' /* webpackChunkName: "pages/ListPage/RecoForYou" */))
const _0cd02556 = () => interopDefault(import('../pages/Member/components/Dressing/Dressing.vue' /* webpackChunkName: "pages/Member/components/Dressing/Dressing" */))
const _18c65be3 = () => interopDefault(import('../pages/Member/components/Ratings/Ratings.vue' /* webpackChunkName: "pages/Member/components/Ratings/Ratings" */))
const _2bb1c8d5 = () => interopDefault(import('../pages/Member/components/UserReport/UserReport.vue' /* webpackChunkName: "pages/Member/components/UserReport/UserReport" */))
const _5454d9ff = () => interopDefault(import('../pages/Account/UserSettings/components/Bank/Bank.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Bank/Bank" */))
const _08cd744d = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/Brand.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/Brand" */))
const _8939b0ea = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/BrandList.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/BrandList" */))
const _fc426142 = () => interopDefault(import('../pages/Account/UserSettings/components/BundleDiscount/BundleDiscount.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BundleDiscount/BundleDiscount" */))
const _53195092 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Color.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Color" */))
const _c067c7b2 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Customization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Customization" */))
const _1bf1db5f = () => interopDefault(import('../pages/Account/UserSettings/components/Holidays/Holidays.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Holidays/Holidays" */))
const _ef243042 = () => interopDefault(import('../pages/Account/UserSettings/components/Menu/Menu.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Menu/Menu" */))
const _004430ad = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/MyAddresses.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/MyAddresses" */))
const _4423188d = () => interopDefault(import('../pages/Account/UserSettings/components/MyPurchases/MyPurchases.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyPurchases/MyPurchases" */))
const _c59821fe = () => interopDefault(import('../pages/Account/UserSettings/components/MySales/MySales.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MySales/MySales" */))
const _7a414b5f = () => interopDefault(import('../pages/Account/UserSettings/components/Notification/Notification.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Notification/Notification" */))
const _4d65045f = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/Settings.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/Settings" */))
const _905060c2 = () => interopDefault(import('../pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization" */))
const _5c2772c2 = () => interopDefault(import('../pages/Account/UserSettings/components/Wallet/Wallet.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Wallet/Wallet" */))
const _97aa3c24 = () => interopDefault(import('../pages/Checkout/Cart/components/PaymentSelector/PaymentSelector.vue' /* webpackChunkName: "pages/Checkout/Cart/components/PaymentSelector/PaymentSelector" */))
const _40eeff2e = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/components/PostalAddress.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/components/PostalAddress" */))
const _ca774708 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal" */))
const _44cd359c = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal" */))
const _6096a738 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal" */))
const _19d802f3 = () => interopDefault(import('../pages/Auth/PasswordReset.vue' /* webpackChunkName: "pages/Auth/PasswordReset" */))
const _690b7214 = () => interopDefault(import('../pages/Account/Inbox/Conversation.vue' /* webpackChunkName: "pages/Account/Inbox/Conversation" */))
const _ed083714 = () => interopDefault(import('../pages/Checkout/Payment/Cancel.vue' /* webpackChunkName: "pages/Checkout/Payment/Cancel" */))
const _fc6b6984 = () => interopDefault(import('../pages/Checkout/Payment/Failure.vue' /* webpackChunkName: "pages/Checkout/Payment/Failure" */))
const _8a951592 = () => interopDefault(import('../pages/Checkout/Payment/Success.vue' /* webpackChunkName: "pages/Checkout/Payment/Success" */))
const _14862582 = () => interopDefault(import('../pages/ListPage/RecoBrandForYou.vue' /* webpackChunkName: "pages/ListPage/RecoBrandForYou" */))
const _0b9f6dd7 = () => interopDefault(import('../pages/AddProduct/index.vue' /* webpackChunkName: "pages/AddProduct/index" */))
const _1a66cd47 = () => interopDefault(import('../pages/Category/index.vue' /* webpackChunkName: "pages/Category/index" */))
const _b8f1c93a = () => interopDefault(import('../pages/Member/index.vue' /* webpackChunkName: "pages/Member/index" */))
const _47c4238c = () => interopDefault(import('../pages/ProductCard/index.vue' /* webpackChunkName: "pages/ProductCard/index" */))
const _65b3f3c3 = () => interopDefault(import('../pages/ListPage/SearchOld.vue' /* webpackChunkName: "pages/ListPage/SearchOld" */))
const _4cda90f3 = () => interopDefault(import('../pages/Bundle/Create.vue' /* webpackChunkName: "pages/Bundle/Create" */))
const _a6b8005a = () => interopDefault(import('../pages/Account/Verified/index.vue' /* webpackChunkName: "pages/Account/Verified/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bienvenue",
    component: _e35258e2,
    name: "Welcome___fr"
  }, {
    path: "/catalog",
    component: _338d5f86,
    name: "Catalog___fr"
  }, {
    path: "/comment-ca-marche",
    component: _fc73efa0,
    name: "HowItWorks___fr"
  }, {
    path: "/conditions-generales",
    component: _762ff01e,
    name: "TermsAndConditions___fr"
  }, {
    path: "/de",
    component: _3c00581c,
    name: "index___de"
  }, {
    path: "/en",
    component: _3c00581c,
    name: "index___en"
  }, {
    path: "/impressum",
    component: _c14b5438,
    name: "Impressum___fr"
  }, {
    path: "/logout",
    component: _cc24ebca,
    name: "Auth-Logout___fr"
  }, {
    path: "/politique-de-confidentialite",
    component: _2b43bb87,
    name: "PrivacyPolicy___fr"
  }, {
    path: "/search",
    component: _55186e74,
    name: "ListPage-Search___fr"
  }, {
    path: "/account/historique-de-recherche",
    component: _2a2a23f7,
    name: "Account-SearchHistory___fr"
  }, {
    path: "/account/inbox",
    component: _4a07679e,
    name: "Account-Inbox___fr"
  }, {
    path: "/account/user-settings",
    component: _c7504ace,
    name: "Account-UserSettings___fr"
  }, {
    path: "/auth/reset-password",
    component: _e3d5de68,
    name: "Auth-PasswordResetRequest___fr"
  }, {
    path: "/auth/signin",
    component: _4e050453,
    name: "Auth-SignIn___fr"
  }, {
    path: "/auth/signup",
    component: _629b5ac9,
    name: "Auth-SignUp___fr"
  }, {
    path: "/auth/social-redirect",
    component: _b8bccb0c,
    name: "Auth-SocialRedirect___fr"
  }, {
    path: "/checkout/cart",
    component: _2a1b9754,
    name: "Checkout-Cart___fr"
  }, {
    path: "/checkout/cart-old",
    component: _1c8f922b,
    name: "Checkout-Cart-old___fr"
  }, {
    path: "/de/allgemeine-geschaftsbedingungen",
    component: _762ff01e,
    name: "TermsAndConditions___de"
  }, {
    path: "/de/catalog",
    component: _338d5f86,
    name: "Catalog___de"
  }, {
    path: "/de/datenschutzrichtlinie-der-clozen-plattform",
    component: _2b43bb87,
    name: "PrivacyPolicy___de"
  }, {
    path: "/de/impressum",
    component: _c14b5438,
    name: "Impressum___de"
  }, {
    path: "/de/logout",
    component: _cc24ebca,
    name: "Auth-Logout___de"
  }, {
    path: "/de/search",
    component: _55186e74,
    name: "ListPage-Search___de"
  }, {
    path: "/de/wie-es-funktioniert",
    component: _fc73efa0,
    name: "HowItWorks___de"
  }, {
    path: "/de/willkommen",
    component: _e35258e2,
    name: "Welcome___de"
  }, {
    path: "/en/catalog",
    component: _338d5f86,
    name: "Catalog___en"
  }, {
    path: "/en/how-it-works",
    component: _fc73efa0,
    name: "HowItWorks___en"
  }, {
    path: "/en/imprint",
    component: _c14b5438,
    name: "Impressum___en"
  }, {
    path: "/en/logout",
    component: _cc24ebca,
    name: "Auth-Logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _2b43bb87,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/search",
    component: _55186e74,
    name: "ListPage-Search___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _762ff01e,
    name: "TermsAndConditions___en"
  }, {
    path: "/en/welcome",
    component: _e35258e2,
    name: "Welcome___en"
  }, {
    path: "/list/articles-populaires",
    component: _3bec31d8,
    name: "ListPage-RecoPopularForYou___fr"
  }, {
    path: "/list/articles-recemment-consultes",
    component: _14b690b8,
    name: "ListPage-RecentlyViewedItems___fr"
  }, {
    path: "/list/dernieres-publications",
    component: _2099d18b,
    name: "ListPage-LatestPublished___fr"
  }, {
    path: "/list/fil-d-actu",
    component: _4afecfdc,
    name: "ListPage-RecoNewsFeed___fr"
  }, {
    path: "/list/highlighted",
    component: _3fe93892,
    name: "ListPage-Highlighted___fr"
  }, {
    path: "/list/mes-favoris",
    component: _1140daa0,
    name: "ListPage-UserFavorites___fr"
  }, {
    path: "/list/recommande-pour-toi",
    component: _7e6f01a1,
    name: "ListPage-RecoForYou___fr"
  }, {
    path: "/de/account/inbox",
    component: _4a07679e,
    name: "Account-Inbox___de"
  }, {
    path: "/de/account/suchverlauf",
    component: _2a2a23f7,
    name: "Account-SearchHistory___de"
  }, {
    path: "/de/account/user-settings",
    component: _c7504ace,
    name: "Account-UserSettings___de"
  }, {
    path: "/de/auth/reset-password",
    component: _e3d5de68,
    name: "Auth-PasswordResetRequest___de"
  }, {
    path: "/de/auth/signin",
    component: _4e050453,
    name: "Auth-SignIn___de"
  }, {
    path: "/de/auth/signup",
    component: _629b5ac9,
    name: "Auth-SignUp___de"
  }, {
    path: "/de/auth/social-redirect",
    component: _b8bccb0c,
    name: "Auth-SocialRedirect___de"
  }, {
    path: "/de/checkout/cart",
    component: _2a1b9754,
    name: "Checkout-Cart___de"
  }, {
    path: "/de/checkout/cart-old",
    component: _1c8f922b,
    name: "Checkout-Cart-old___de"
  }, {
    path: "/de/list/beliebte-artikel",
    component: _3bec31d8,
    name: "ListPage-RecoPopularForYou___de"
  }, {
    path: "/de/list/empfohlen-fur-dich",
    component: _7e6f01a1,
    name: "ListPage-RecoForYou___de"
  }, {
    path: "/de/list/highlighted",
    component: _3fe93892,
    name: "ListPage-Highlighted___de"
  }, {
    path: "/de/list/letzte-veroffentlichungen",
    component: _2099d18b,
    name: "ListPage-LatestPublished___de"
  }, {
    path: "/de/list/meine-lieblingsartikel",
    component: _1140daa0,
    name: "ListPage-UserFavorites___de"
  }, {
    path: "/de/list/news-feed",
    component: _4afecfdc,
    name: "ListPage-RecoNewsFeed___de"
  }, {
    path: "/de/list/zuletzt-angesehenen-artikel",
    component: _14b690b8,
    name: "ListPage-RecentlyViewedItems___de"
  }, {
    path: "/en/account/inbox",
    component: _4a07679e,
    name: "Account-Inbox___en"
  }, {
    path: "/en/account/search-history",
    component: _2a2a23f7,
    name: "Account-SearchHistory___en"
  }, {
    path: "/en/account/user-settings",
    component: _c7504ace,
    name: "Account-UserSettings___en"
  }, {
    path: "/en/auth/reset-password",
    component: _e3d5de68,
    name: "Auth-PasswordResetRequest___en"
  }, {
    path: "/en/auth/signin",
    component: _4e050453,
    name: "Auth-SignIn___en"
  }, {
    path: "/en/auth/signup",
    component: _629b5ac9,
    name: "Auth-SignUp___en"
  }, {
    path: "/en/auth/social-redirect",
    component: _b8bccb0c,
    name: "Auth-SocialRedirect___en"
  }, {
    path: "/en/checkout/cart",
    component: _2a1b9754,
    name: "Checkout-Cart___en"
  }, {
    path: "/en/checkout/cart-old",
    component: _1c8f922b,
    name: "Checkout-Cart-old___en"
  }, {
    path: "/en/list/highlighted",
    component: _3fe93892,
    name: "ListPage-Highlighted___en"
  }, {
    path: "/en/list/latest-publications",
    component: _2099d18b,
    name: "ListPage-LatestPublished___en"
  }, {
    path: "/en/list/my-favorites",
    component: _1140daa0,
    name: "ListPage-UserFavorites___en"
  }, {
    path: "/en/list/news-feed",
    component: _4afecfdc,
    name: "ListPage-RecoNewsFeed___en"
  }, {
    path: "/en/list/popular-articles",
    component: _3bec31d8,
    name: "ListPage-RecoPopularForYou___en"
  }, {
    path: "/en/list/recently-viewed-items",
    component: _14b690b8,
    name: "ListPage-RecentlyViewedItems___en"
  }, {
    path: "/en/list/recommended-for-you",
    component: _7e6f01a1,
    name: "ListPage-RecoForYou___en"
  }, {
    path: "/Member/components/Dressing/Dressing",
    component: _0cd02556,
    name: "Member-components-Dressing-Dressing___fr"
  }, {
    path: "/Member/components/Ratings/Ratings",
    component: _18c65be3,
    name: "Member-components-Ratings-Ratings___fr"
  }, {
    path: "/Member/components/UserReport/UserReport",
    component: _2bb1c8d5,
    name: "Member-components-UserReport-UserReport___fr"
  }, {
    path: "/Account/UserSettings/components/Bank/Bank",
    component: _5454d9ff,
    name: "Account-UserSettings-components-Bank-Bank___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/Brand",
    component: _08cd744d,
    name: "Account-UserSettings-components-BrandList-Brand___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/BrandList",
    component: _8939b0ea,
    name: "Account-UserSettings-components-BrandList-BrandList___fr"
  }, {
    path: "/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _fc426142,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Color",
    component: _53195092,
    name: "Account-UserSettings-components-Customization-Color___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Customization",
    component: _c067c7b2,
    name: "Account-UserSettings-components-Customization-Customization___fr"
  }, {
    path: "/Account/UserSettings/components/Holidays/Holidays",
    component: _1bf1db5f,
    name: "Account-UserSettings-components-Holidays-Holidays___fr"
  }, {
    path: "/Account/UserSettings/components/Menu/Menu",
    component: _ef243042,
    name: "Account-UserSettings-components-Menu-Menu___fr"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _004430ad,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___fr"
  }, {
    path: "/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _4423188d,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___fr"
  }, {
    path: "/Account/UserSettings/components/MySales/MySales",
    component: _c59821fe,
    name: "Account-UserSettings-components-MySales-MySales___fr"
  }, {
    path: "/Account/UserSettings/components/Notification/Notification",
    component: _7a414b5f,
    name: "Account-UserSettings-components-Notification-Notification___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/Settings",
    component: _4d65045f,
    name: "Account-UserSettings-components-Settings-Settings___fr"
  }, {
    path: "/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _905060c2,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___fr"
  }, {
    path: "/Account/UserSettings/components/Wallet/Wallet",
    component: _5c2772c2,
    name: "Account-UserSettings-components-Wallet-Wallet___fr"
  }, {
    path: "/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _97aa3c24,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___fr"
  }, {
    path: "/de/Member/components/Dressing/Dressing",
    component: _0cd02556,
    name: "Member-components-Dressing-Dressing___de"
  }, {
    path: "/de/Member/components/Ratings/Ratings",
    component: _18c65be3,
    name: "Member-components-Ratings-Ratings___de"
  }, {
    path: "/de/Member/components/UserReport/UserReport",
    component: _2bb1c8d5,
    name: "Member-components-UserReport-UserReport___de"
  }, {
    path: "/en/Member/components/Dressing/Dressing",
    component: _0cd02556,
    name: "Member-components-Dressing-Dressing___en"
  }, {
    path: "/en/Member/components/Ratings/Ratings",
    component: _18c65be3,
    name: "Member-components-Ratings-Ratings___en"
  }, {
    path: "/en/Member/components/UserReport/UserReport",
    component: _2bb1c8d5,
    name: "Member-components-UserReport-UserReport___en"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _40eeff2e,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___fr"
  }, {
    path: "/de/Account/UserSettings/components/Bank/Bank",
    component: _5454d9ff,
    name: "Account-UserSettings-components-Bank-Bank___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/Brand",
    component: _08cd744d,
    name: "Account-UserSettings-components-BrandList-Brand___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/BrandList",
    component: _8939b0ea,
    name: "Account-UserSettings-components-BrandList-BrandList___de"
  }, {
    path: "/de/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _fc426142,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Color",
    component: _53195092,
    name: "Account-UserSettings-components-Customization-Color___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Customization",
    component: _c067c7b2,
    name: "Account-UserSettings-components-Customization-Customization___de"
  }, {
    path: "/de/Account/UserSettings/components/Holidays/Holidays",
    component: _1bf1db5f,
    name: "Account-UserSettings-components-Holidays-Holidays___de"
  }, {
    path: "/de/Account/UserSettings/components/Menu/Menu",
    component: _ef243042,
    name: "Account-UserSettings-components-Menu-Menu___de"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _004430ad,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___de"
  }, {
    path: "/de/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _4423188d,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___de"
  }, {
    path: "/de/Account/UserSettings/components/MySales/MySales",
    component: _c59821fe,
    name: "Account-UserSettings-components-MySales-MySales___de"
  }, {
    path: "/de/Account/UserSettings/components/Notification/Notification",
    component: _7a414b5f,
    name: "Account-UserSettings-components-Notification-Notification___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/Settings",
    component: _4d65045f,
    name: "Account-UserSettings-components-Settings-Settings___de"
  }, {
    path: "/de/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _905060c2,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___de"
  }, {
    path: "/de/Account/UserSettings/components/Wallet/Wallet",
    component: _5c2772c2,
    name: "Account-UserSettings-components-Wallet-Wallet___de"
  }, {
    path: "/de/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _97aa3c24,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___de"
  }, {
    path: "/en/Account/UserSettings/components/Bank/Bank",
    component: _5454d9ff,
    name: "Account-UserSettings-components-Bank-Bank___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/Brand",
    component: _08cd744d,
    name: "Account-UserSettings-components-BrandList-Brand___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/BrandList",
    component: _8939b0ea,
    name: "Account-UserSettings-components-BrandList-BrandList___en"
  }, {
    path: "/en/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _fc426142,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Color",
    component: _53195092,
    name: "Account-UserSettings-components-Customization-Color___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Customization",
    component: _c067c7b2,
    name: "Account-UserSettings-components-Customization-Customization___en"
  }, {
    path: "/en/Account/UserSettings/components/Holidays/Holidays",
    component: _1bf1db5f,
    name: "Account-UserSettings-components-Holidays-Holidays___en"
  }, {
    path: "/en/Account/UserSettings/components/Menu/Menu",
    component: _ef243042,
    name: "Account-UserSettings-components-Menu-Menu___en"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _004430ad,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___en"
  }, {
    path: "/en/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _4423188d,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___en"
  }, {
    path: "/en/Account/UserSettings/components/MySales/MySales",
    component: _c59821fe,
    name: "Account-UserSettings-components-MySales-MySales___en"
  }, {
    path: "/en/Account/UserSettings/components/Notification/Notification",
    component: _7a414b5f,
    name: "Account-UserSettings-components-Notification-Notification___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/Settings",
    component: _4d65045f,
    name: "Account-UserSettings-components-Settings-Settings___en"
  }, {
    path: "/en/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _905060c2,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___en"
  }, {
    path: "/en/Account/UserSettings/components/Wallet/Wallet",
    component: _5c2772c2,
    name: "Account-UserSettings-components-Wallet-Wallet___en"
  }, {
    path: "/en/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _97aa3c24,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___en"
  }, {
    path: "/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _ca774708,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _44cd359c,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _6096a738,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___fr"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _40eeff2e,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___de"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _40eeff2e,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___en"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _ca774708,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _44cd359c,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _6096a738,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___de"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _ca774708,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _44cd359c,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _6096a738,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___en"
  }, {
    path: "/de/auth/reset-password/reset/:token",
    component: _19d802f3,
    name: "Auth-PasswordReset___de"
  }, {
    path: "/en/auth/reset-password/reset/:token",
    component: _19d802f3,
    name: "Auth-PasswordReset___en"
  }, {
    path: "/auth/reset-password/reset/:token",
    component: _19d802f3,
    name: "Auth-PasswordReset___fr"
  }, {
    path: "/de/account/inbox/:conversationId",
    component: _690b7214,
    name: "Account-Inbox-Conversation___de"
  }, {
    path: "/en/account/inbox/:conversationId",
    component: _690b7214,
    name: "Account-Inbox-Conversation___en"
  }, {
    path: "/de/checkout/payment/:transactionId/cancel",
    component: _ed083714,
    name: "Checkout-Payment-Cancel___de"
  }, {
    path: "/de/checkout/payment/:transactionId/failure",
    component: _fc6b6984,
    name: "Checkout-Payment-Failure___de"
  }, {
    path: "/de/checkout/payment/:transactionId/success",
    component: _8a951592,
    name: "Checkout-Payment-Success___de"
  }, {
    path: "/de/list/marke/:slug/fur-dich",
    component: _14862582,
    name: "ListPage-RecoBrandForYou___de"
  }, {
    path: "/en/checkout/payment/:transactionId/cancel",
    component: _ed083714,
    name: "Checkout-Payment-Cancel___en"
  }, {
    path: "/en/checkout/payment/:transactionId/failure",
    component: _fc6b6984,
    name: "Checkout-Payment-Failure___en"
  }, {
    path: "/en/checkout/payment/:transactionId/success",
    component: _8a951592,
    name: "Checkout-Payment-Success___en"
  }, {
    path: "/en/list/brand/:slug/for-you",
    component: _14862582,
    name: "ListPage-RecoBrandForYou___en"
  }, {
    path: "/account/inbox/:conversationId",
    component: _690b7214,
    name: "Account-Inbox-Conversation___fr"
  }, {
    path: "/de/artikel-hinzufugen/:id?",
    component: _0b9f6dd7,
    name: "AddProduct___de"
  }, {
    path: "/de/category/:slug",
    component: _1a66cd47,
    name: "Category___de"
  }, {
    path: "/de/member/:slug",
    component: _b8f1c93a,
    name: "Member___de"
  }, {
    path: "/de/product/:slug",
    component: _47c4238c,
    name: "ProductCard___de"
  }, {
    path: "/de/search/:query",
    component: _65b3f3c3,
    name: "ListPage-SearchOld___de"
  }, {
    path: "/en/add-item/:id?",
    component: _0b9f6dd7,
    name: "AddProduct___en"
  }, {
    path: "/en/category/:slug",
    component: _1a66cd47,
    name: "Category___en"
  }, {
    path: "/en/member/:slug",
    component: _b8f1c93a,
    name: "Member___en"
  }, {
    path: "/en/product/:slug",
    component: _47c4238c,
    name: "ProductCard___en"
  }, {
    path: "/en/search/:query",
    component: _65b3f3c3,
    name: "ListPage-SearchOld___en"
  }, {
    path: "/checkout/payment/:transactionId/cancel",
    component: _ed083714,
    name: "Checkout-Payment-Cancel___fr"
  }, {
    path: "/checkout/payment/:transactionId/failure",
    component: _fc6b6984,
    name: "Checkout-Payment-Failure___fr"
  }, {
    path: "/checkout/payment/:transactionId/success",
    component: _8a951592,
    name: "Checkout-Payment-Success___fr"
  }, {
    path: "/de/user/:user/create-bundle",
    component: _4cda90f3,
    name: "Bundle-Create___de"
  }, {
    path: "/en/user/:user/create-bundle",
    component: _4cda90f3,
    name: "Bundle-Create___en"
  }, {
    path: "/list/marque/:slug/pour-toi",
    component: _14862582,
    name: "ListPage-RecoBrandForYou___fr"
  }, {
    path: "/ajout-article/:id?",
    component: _0b9f6dd7,
    name: "AddProduct___fr"
  }, {
    path: "/category/:slug",
    component: _1a66cd47,
    name: "Category___fr"
  }, {
    path: "/member/:slug",
    component: _b8f1c93a,
    name: "Member___fr"
  }, {
    path: "/product/:slug",
    component: _47c4238c,
    name: "ProductCard___fr"
  }, {
    path: "/search/:query",
    component: _65b3f3c3,
    name: "ListPage-SearchOld___fr"
  }, {
    path: "/de/:slug/willkommen",
    component: _a6b8005a,
    name: "Account-Verified___de"
  }, {
    path: "/en/:slug/welcome",
    component: _a6b8005a,
    name: "Account-Verified___en"
  }, {
    path: "/user/:user/create-bundle",
    component: _4cda90f3,
    name: "Bundle-Create___fr"
  }, {
    path: "/",
    component: _3c00581c,
    name: "index___fr"
  }, {
    path: "/:slug/bienvenue",
    component: _a6b8005a,
    name: "Account-Verified___fr"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      const res = require('qs').stringify(query);
      return res ? '?' + res : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
