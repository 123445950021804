export default function ({ route, redirect, app }) {
  /* get the localized path from the current route path
     if route.path === '/identificacion.html' but current locale is 'fr' -> it will return '/connexion.html'
   */
  const expectedPath = decodeURIComponent(app.localePath(route.path));
  // If the current path doesn't match the localized one, then we're not on the right path.
  if (decodeURIComponent(route.path) !== expectedPath) {
    // redirect to correct one
    return redirect(301, expectedPath);
  }
}
